import '../IncidentHistory.css';
import React, { useState } from 'react';
import { IncidentDTO } from '../../../DTO/IncidentDTO';
import { ProductDTO } from '../../../DTO/ProductDTO';
import { IncidentUpdateDTO } from '../../../DTO/IncidentUpdateDTO';
import { DisplayDateString } from '../../../DTO/Constants/Function/DateConversion';
import { Icon } from "hcss-components";
import { Link } from "react-router-dom";
import { getIncidentAsync } from "../../../API/getAPI";

import { IsUserAdmin } from '../../../Auth/UserAuth';

export const NotActiveIncident = (props: IncidentDTO):JSX.Element => {
  const [isMounted, setIsMounted] = useState(false);
  const [formProperties, setFormProperties] = useState<IncidentDTO>();
    const productsAffectedDisplay: string = props.products.map((product: ProductDTO, index: number) => {
        return product.name;
    }).join(', ');

    const handleClick = () => {
        setIsMounted(true);
        async function getIncident() {
          await getIncidentAsync(props.id).then(resolution => {
            if(isMounted) {
              setFormProperties(resolution.data);
            }
          });
        }
        getIncident();
      }

    let statusUpdatesDisplay: JSX.Element[] = [];
    if(props.incidentUpdates) {
        statusUpdatesDisplay = props.incidentUpdates.map((incident: IncidentUpdateDTO, index: number) => {
            const updateTime: string = DisplayDateString(new Date(incident.time), 'LLL', false, true);
            return(
                <li key={index}>{updateTime} - {incident.details}</li>
            );
        })
    }

    const renderEditIncidentButton = () => {
      if (IsUserAdmin()) {
        return(
          <div data-testid='edit'>
            <Link to={`/Forms/Incident/${props.id}`} onClick={handleClick}>Edit Incident
              <Icon name='edit' margin='both'></Icon>
            </Link>
          </div>
        );
      }
    }

    const startTime: string = DisplayDateString(new Date(props.startTime), 'LLL', false, true);
    let resolveTime: string = '--';
    if(props.resolveTime) {
        resolveTime = DisplayDateString(new Date(props.resolveTime), 'LLL', false, true);
    }

    return(
    <div className='incident-card'>
        <div data-testid='name' style={{fontSize: '20px'}}>{props.title}</div>
        <div  data-testid='description'>{props.description}</div>
        <div  data-testid='products-affected'><b>Products Affected: </b>{productsAffectedDisplay}</div>
        <div  data-testid='start-date'><b>Start Date Time: </b>{startTime}</div>
        <div  data-testid='resolved-date'><b>Resolved Date Time: </b>{resolveTime}</div>
        <div style={{display: statusUpdatesDisplay.length ? 'block' : 'none'}}>
            <b>Status Updates:</b>
            <ul>
                {statusUpdatesDisplay}
            </ul>
        </div>
        {renderEditIncidentButton()}
    </div>
    );
}